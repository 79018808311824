import { slugifyCustom } from './utils';

export const OAD_META_DESCRIPTION =
  "Testez dès maintenant nos Outils d’Aide à la Décision (OAD) agricoles pour évaluer les impacts d'un changement en quelques minutes et prendre les bonnes décisions.";
export const OAD_META_KEYWORDS = "oad agriculture, outils d'aide à la décision agriculture";
export const oadMetaTitle = (title) => {
  return `OAD ${title} • Breeder Connect&apos;`;
};

/**
 * Returns a filter function to remove a resource from a list.
 *
 * Comparison is based on the ids the resources.
 */
export const filterOtherThan = (resourceWeDontWant) => (resource) => {
  return resource.idResource !== resourceWeDontWant.idResource;
};

/**
 *
 * @param {*} resource is the concerned resource
 * @returns a slug (id - slugified title)
 */
export const getResourceSlug = (resource) => {
  if (resource.resourceType === 'OAD') return resource.code;
  else if (resource.resourceType === 'ARTICLE' || resource.resourceType === 'CHANNEL') {
    const slug = slugifyCustom(resource.title);
    if (resource.resourceType === 'ARTICLE' && resource.slug === slug) {
      return slug;
    }
    return `${resource.idResource}_${slug}`;
  } else return 'ext';
};

export const getServiceSlug = (service) => {
  return service.slug || slugifyCustom(service.label);
};
