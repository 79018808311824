import { thematicList } from '../config/config';
import Storage from '@aws-amplify/storage';
import slugify from 'slugify';

export const isSubscribed = (user, community) => {
  let result = false;
  if (user?.resources) {
    result = user.resources.some((el) => el.idResource === community.idResource);
  }
  return result;
};

export const sortResources = (resources) => {
  if (resources) {
    return resources.sort((a, b) => {
      const priority = { CHANNEL: 1, OAD: 2, ARTICLE: 3 };
      if (priority[a.resourceType] === priority[b.resourceType]) {
        return a.title - b.title;
      }
      return priority[a.resourceType] - priority[b.resourceType];
    });
  } else {
    return [];
  }
};

export const sortFolders = (folders) => {
  if (folders) {
    return folders.sort((a, b) => {
      return (
        Object.keys(thematicList).findIndex((el) => el === a.code) -
        Object.keys(thematicList).findIndex((el) => el === b.code)
      );
    });
  } else {
    return [];
  }
};

export const clearCognitoStorage = () => {
  const items = Object.keys(localStorage).filter((el) => el.startsWith('Cognito'));
  items.forEach((item) => localStorage.removeItem(item));
};

export const getImageUrl = async (picture) => {
  return picture
    ? picture.startsWith('http') ||
      picture.startsWith('data') ||
      picture.startsWith('/img') ||
      picture.startsWith('/icons')
      ? picture
      : process.env.NEXT_PUBLIC_STATIC_DOMAIN
      ? `https://${process.env.NEXT_PUBLIC_STATIC_DOMAIN}/${picture}`
      : Storage.get(picture)
    : '/img/bg-card-contact.jpg';
};

export const slugifyCustom = (text) => {
  const slug = slugify(text, { lower: true, locale: 'fr', remove: /[*+~.()'"!:@,?]/g });
  return slug.replace('/', '-');
};

export const checkNumber = (evt) => {
  if (evt.target.type === 'number' && isNaN(parseInt(evt.target.value))) {
    evt.target.value = '';
  }
};
