import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Main from '../buttons/main';

const StyleModal = styled(Modal)`
  .modal-dialog {
    max-width: 400px;
  }

  .modal-content {
    border-radius: 20px;
  }

  .modal-header {
    border: none;
    padding-bottom: 0;
  }

  .modal-body {
    padding-bottom: 0;
  }

  .modal-footer {
    border: none;
    padding-top: 0;
  }

  .modal-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    margin-left: 8px;
  }

  p {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
  }

  .btn-close {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 4px;
    background-color: #4e5fb7;
    opacity: 1;
    background-image: url('/icons/cross.svg');
    background-size: 25px 25px;
    margin: 0;
  }
`;

const ImpossibleAction = (props) => {
  return (
    <StyleModal
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.isConnected ? 'Action impossible' : 'Inscrivez-vous !'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.isConnected ? (
          <p>Votre compte est en attente de confirmation du référent.</p>
        ) : (
          <p>
            Profitez de toutes les fonctionnalités de Breeder Connect&apos; : Créez votre compte gratuitement ! <br />
            Recevez une alerte pour suivre ce qui se dit sur vos thématiques d&apos;intérêts, questionnez un expert en
            privé…
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Main text="OK" onButtonClick={props.handleClose} style={{ minWidth: '113px' }} />
      </Modal.Footer>
    </StyleModal>
  );
};

ImpossibleAction.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  isConnected: PropTypes.bool
};

export default ImpossibleAction;
