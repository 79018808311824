export const url = {
  sso: process.env.NEXT_PUBLIC_SSO_URL,
  connect: process.env.NEXT_PUBLIC_CONNECT_URL,
  landfiles: process.env.NEXT_PUBLIC_LANDFILES_URL
};

export const thematicList = {
  'nutrition-sante': 'Nutrition-santé',
  'veaux-genisses': 'Veaux-Génisses',
  'agriculture-bio': 'Agriculture Bio',
  'robot-traite': 'Robot de traite',
  reproduction: 'Reproduction-IPE'
};

export const amplify = {
  region: process.env.NEXT_PUBLIC_AMPLIFY_REGION
};

export const algolia = {
  key: process.env.NEXT_PUBLIC_ALGOLIA_KEY,
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  name: process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME
};

export const revalidate = 7200;
