import React from 'react';
import { Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Main from '../buttons/main';

const StyleModal = styled(Modal)`
  .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    border-radius: 20px;
  }

  .modal-header {
    border: none;
    padding-bottom: 0;
  }

  .modal-body {
    padding-bottom: 0;
  }

  .modal-footer {
    border: none;
    padding-top: 0;
  }

  .modal-title {
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.22;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    margin-left: 8px;
  }

  p {
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
    color: #5d6267;
    margin-bottom: 0;
  }

  .video {
    margin: 20px 0;
  }

  .btn-close {
    width: 30px;
    height: 30px;
    padding: 0;
    border-radius: 4px;
    background-color: #4e5fb7;
    opacity: 1;
    background-image: url('/icons/cross.svg');
    background-size: 25px 25px;
    margin: 0;
  }

  .container {
    position: relative;
    overflow: hidden;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    margin: 20px 0;
  }

  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

const PaymentModal = ({ show, handleClose, link }) => {
  return (
    <StyleModal
      show={show}
      onHide={handleClose}
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">Contenu payant</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ce contenu est payant ! Si vous désirez y accéder, merci de procéder au paiement.</p>
      </Modal.Body>
      <Modal.Footer>
        <Main text="Aller au paiement" url={link} targetBlank={true} style={{ minWidth: '130px' }} />
        <Main text="Annuler" onButtonClick={handleClose} style={{ minWidth: '130px' }} />
      </Modal.Footer>
    </StyleModal>
  );
};

PaymentModal.propTypes = {
  show: PropTypes.bool,
  handleClose: PropTypes.func,
  link: PropTypes.string
};

export default PaymentModal;
