import React from 'react';
import styled from 'styled-components';

const StyleBreederWeb = styled.div`
  border: solid 2px #e49938;
  width: 200px;
  max-height: 70px;
  display: flex;
  align-self: center;
  border-radius: 12px;
  padding: 8px 6px;
  img {
    max-height: 30px;
    width: 100%;
  }
  .description {
    padding-top: 3px;
    width: 100%;
    justify-content: center;
  }
  .description p {
    text-decoration: none;
    color: #212529;
    font-size: 10px;
    font-weight: 650;
    font-style: italic;
  }

  @media all and (max-width: 915px) {
    display: none;
  }
`;

const ButtonBreederWeb = () => {
  return (
    <StyleBreederWeb>
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a target="_blank" href="https://www.breeder.fr/">
        <img src="/img/breeder-web.png" alt="Breeder Web" />
        <div className="description">
          <p>Cliquez ici pour votre gestion de troupeau</p>
        </div>
      </a>
    </StyleBreederWeb>
  );
};

ButtonBreederWeb.propTypes = {};

export default ButtonBreederWeb;
