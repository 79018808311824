import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Link from 'next/link';
import { url } from '../../config/config';
import {
  Navbar,
  Nav,
  NavDropdown,
  Image,
  Offcanvas,
  Accordion,
  ListGroup,
  Popover,
  OverlayTrigger
} from 'react-bootstrap';
import Auth from '@aws-amplify/auth';
import { useRouter } from 'next/router';
import { UserContext } from '../../pages/_app';
import { clearCognitoStorage } from '../../tools/utils';
import { UserCircleIcon, SearchIcon, MenuIcon } from '@heroicons/react/outline';
import SearchInput from '../shared/searchInput';
import ButtonBreederWeb from '../buttons/buttonBreederWeb';

const NavContainer = styled.div`
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 7;

  nav {
    height: 88px;
    width: 100%;
    max-width: 1350px;
    padding: 0 30px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between !important;
  }

  .menuContainer {
    width: 47px;
    height: 44px;
    margin: 0 8px;
    padding: 8px;
    border-radius: 4px;
    background-color: #4e5fb7;
    display: none;
    cursor: pointer;
  }

  .menu {
    width: 14px;
    height: 14px;
    color: white;
  }

  .header__logo {
    width: 145.2px;
    height: 54.6px;
    object-fit: contain;
    cursor: pointer;
  }

  .nav-link {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #212529 !important;
  }

  .navbar-brand {
    margin-right: 40px;
  }

  .navbar-nav {
    margin-right: 0 !important;
    display: flex;
    justify-content: center;
    a {
      position: relative;
    }
  }

  .navbar-expand .navbar-nav .dropdown-menu {
    top: 40px;
  }

  .activ {
    width: 80%;
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: -5px;
  }

  .mr-auto a:hover {
    color: #4e5fb7 !important;
  }

  .logout {
    margin: 0;
  }

  .avatar {
    width: 54px;
    height: 54px;
    object-fit: cover;
  }

  .dropdown {
    display: flex;
    align-items: center;
  }

  .list-unstyled {
    margin-bottom: 0;
  }

  .icon {
    width: 25px;
    height: 25px;
    color: #4e5fb7;
  }

  .headerBtn {
    width: 98px;
    height: 88px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: #4e5fb7;
    cursor: pointer;
  }

  .headerBtn:hover {
    background-color: rgba(199, 212, 106, 0.25);
    color: #212529;
    .icon {
      color: #212529;
    }
  }

  #basic-nav-dropdown {
    margin-bottom: 1px;
  }

  /* Mobile */
  @media all and (max-width: 915px) {
    .menuContainer {
      display: flex;
      .menu {
        height: 30px;
        width: 30px;
      }
    }

    .navbar-nav.mr-auto {
      display: none;
    }

    nav {
      justify-content: center;
      padding: 0;
      height: 63px;
    }

    .header__logo {
      height: 43px;
      width: 108px;
    }

    .avatar {
      width: 35px;
      height: 35px;
    }

    .headerBtn {
      width: 40px;
      height: 63px;
    }

    .btnText {
      display: none;
    }

    .navbar-brand {
      margin-right: 0;
    }
  }
`;

const StyleCanvas = styled(Offcanvas)`
  .offcanvas-title {
    margin: auto;
  }

  .accordion-item {
    border: none;
  }

  .accordion-body {
    padding: 0;
  }

  .header__logo {
    width: 145.2px;
    height: 54.6px;
    object-fit: contain;
    cursor: pointer;
  }

  .list-group-item {
    background-color: #f4f4f4;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    border: none;
    cursor: pointer;
  }

  .accordion-button {
    padding-left: 1rem;
  }

  .accordion-button:focus {
    border: none;
  }

  .accordion-button:not(.collapsed) {
    background-color: #ffffff;
    color: #4e5fb7;
  }

  .activ {
    margin-left: 5px;
  }

  .nav-link,
  .accordion-button {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #212529;
    display: flex;
  }

  .btn-close {
    width: 45px;
    height: 45px;
    padding: 0;
    border-radius: 4px;
    margin-right: -37px;
    background-color: #4e5fb7;
    opacity: 1;
    background-image: url('/icons/cross.svg');
    background-size: 35px 35px;
  }
`;

const Header = ({ current, folders }) => {
  const [show, setShow] = useState(false);
  const [showMobile, setShowMobile] = useState(false);

  const handleClose = () => setShowMobile(false);
  const handleShow = () => setShowMobile(true);

  let connectUrl = url.sso;

  const router = useRouter();

  if (router.query.idToValidate) connectUrl += '?idToValidate=' + router.query.idToValidate;

  const { user, updateUser } = useContext(UserContext);

  const popover = (
    <Popover style={{ maxWidth: '751px', width: '97%', backgroundColor: '#f4f4f4' }} id="popover-basic">
      <Popover.Body style={{ padding: '0' }}>
        <SearchInput />
      </Popover.Body>
    </Popover>
  );

  async function logout() {
    try {
      await Auth.signOut();
      clearCognitoStorage();
      updateUser();
      router.push('/');
    } catch (error) {
      console.log('error signing out: ', error);
    }
  }

  return (
    <NavContainer>
      <Navbar>
        <div className="menuContainer">
          <MenuIcon className="menu" onClick={handleShow} />
        </div>
        <Link href="/" passHref>
          <Navbar.Brand>
            <img className="header__logo" src="/logo.svg" alt="logo" />
          </Navbar.Brand>
        </Link>
        <Nav className="mr-auto">
          <NavDropdown
            title="Dossiers"
            id="basic-nav-dropdown"
            show={show}
            onMouseEnter={() => setShow(true)}
            onMouseLeave={() => setShow(false)}
          >
            {folders?.map((file) => (
              <Link key={file.idThematic} href={`/dossier/${file.code}`} passHref>
                <NavDropdown.Item>{file.label}</NavDropdown.Item>
              </Link>
            ))}
          </NavDropdown>
          <Link href="/oad/all" passHref>
            <Nav.Link>
              Aides à la décision
              <div className="activ" hidden={current !== 'OAD'}>
                <img src="/icons/icon-connect-left-violet.svg" alt="picto" />
              </div>
            </Nav.Link>
          </Link>
          <Link href="/service/all" passHref>
            <Nav.Link>
              Services
              <div className="activ" hidden={current !== 'SERVICE'}>
                <img src="/icons/icon-connect-left-violet.svg" alt="picto" />
              </div>
            </Nav.Link>
          </Link>
          <Link href="/channel/all" passHref>
            <Nav.Link>
              Communautés
              <div className="activ" hidden={current !== 'CHANNEL'}>
                <img src="/icons/icon-connect-left-violet.svg" alt="picto" />
              </div>
            </Nav.Link>
          </Link>
          <Link href="/expert/all" passHref>
            <Nav.Link>
              Experts
              <div className="activ" hidden={current !== 'EXPERT'}>
                <img src="/icons/icon-connect-left-violet.svg" alt="picto" />
              </div>
            </Nav.Link>
          </Link>
        </Nav>
        <Nav>
          <ButtonBreederWeb />
          <OverlayTrigger trigger="click" placement="bottom-end" overlay={popover}>
            <div className="headerBtn">
              <SearchIcon className="icon" />
              <span className="btnText">Rechercher</span>
            </div>
          </OverlayTrigger>
          {user ? (
            <NavDropdown
              title={user.avatar ? <Image className="avatar" alt="avatar" src={user.avatar} roundedCircle /> : ''}
              id="basic-nav-dropdown"
              align="end"
            >
              <Link href="/account" passHref>
                <NavDropdown.Item>Mon compte</NavDropdown.Item>
              </Link>
              <NavDropdown.Item onClick={logout}>Se déconnecter</NavDropdown.Item>
            </NavDropdown>
          ) : (
            <Link href={connectUrl}>
              <div className="headerBtn">
                <UserCircleIcon className="icon" />
                <span className="btnText">Se connecter</span>
              </div>
            </Link>
          )}
        </Nav>
      </Navbar>
      <StyleCanvas show={showMobile} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img className="header__logo" src="/logo.svg" alt="logo" />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav defaultActiveKey="/home" className="flex-column">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Dossiers</Accordion.Header>
                <Accordion.Body>
                  <ListGroup>
                    {folders?.map((file) => (
                      <ListGroup.Item
                        key={file.idThematic}
                        onClick={() => {
                          router.push({
                            pathname: '/dossier/[code]',
                            query: { code: file.code }
                          });
                          handleClose();
                        }}
                      >
                        {file.label}
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <Nav.Link href="/oad/all">
              Aides à la décision
              <div className="activ" hidden={current !== 'OAD'}>
                <img src="/icons/icon-connect-right-violet.svg" alt="picto" />
              </div>
            </Nav.Link>
            <Nav.Link href="/service/all">
              Services
              <div className="activ" hidden={current !== 'SERVICE'}>
                <img src="/icons/icon-connect-right-violet.svg" alt="picto" />
              </div>
            </Nav.Link>
            <Nav.Link href="/channel/all">
              Communautés
              <div className="activ" hidden={current !== 'CHANNEL'}>
                <img src="/icons/icon-connect-right-violet.svg" alt="picto" />
              </div>
            </Nav.Link>
            <Nav.Link href="/expert/all">
              Experts
              <div className="activ" hidden={current !== 'EXPERT'}>
                <img src="/icons/icon-connect-right-violet.svg" alt="picto" />
              </div>
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </StyleCanvas>
    </NavContainer>
  );
};

Header.propTypes = {
  current: PropTypes.string,
  folders: PropTypes.array
};

export default Header;
