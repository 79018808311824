import { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { algolia } from '../../config/config';
import { UserContext } from '../../pages/_app';
import ImpossibleAction from '../shared/impossibleAction';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, SearchBox, connectStateResults } from 'react-instantsearch-dom';
import { isSubscribed, slugifyCustom } from '../../tools/utils';
import { subscribe, getResource } from '../../queries/backendQueries';
import { Spinner } from 'react-bootstrap';
import { getResourceSlug } from '../../tools/resources';
import { SearchIcon } from '@heroicons/react/outline';
import PaymentModal from '../payment/paymentModal';

const SearchInputContainer = styled.div`
  margin: 20px;
  position: relative;

  .ais-SearchBox-form {
    display: flex;
    align-items: center;
  }

  .ais-SearchBox-input {
    max-width: 550px;
    width: 100%;
    height: 56px;
    padding-left: 16px;
    border-radius: 8px 0 0 8px;
    background-color: #ffffff;
    border: none;
    font-size: 15px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.47;
    letter-spacing: normal;
    text-align: left;
  }

  .hasResults {
    .ais-SearchBox-form {
      .ais-SearchBox-input {
        border-bottom-left-radius: 0;
      }
    }
  }

  .ais-SearchBox-input:focus {
    outline: none;
  }

  .ais-SearchBox-input::placeholder {
    color: #5d6267;
  }

  .ais-SearchBox-submit {
    width: 161px;
    height: 56px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    background-image: linear-gradient(to bottom, #ee8b0d, #e49a3b);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 15px;
    border: none;
    font-size: 15px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
  }

  .ais-SearchBox-reset {
    display: none;
  }

  .ais-SearchBox-submitIcon {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }

  .results {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
    font-size: 16px;
    line-height: 1.2;
    padding: 0 26px;
    cursor: pointer;
  }

  .results:hover {
    background-color: var(--tomato-light);
  }

  .search-proposals {
    max-width: 550px;
    position: absolute;
    z-index: 6;
    background-color: white;
    border: none;
    border-radius: 0 0 8px 8px;
    color: var(--black);
    left: 0;
    right: 34px;
  }
`;

const SearchInput = () => {
  const router = useRouter();
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clicked, setClicked] = useState(null);
  const [showResult, setShowResult] = useState(false);
  const [queryString, setQueryString] = useState(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const { user, updateUser } = useContext(UserContext);
  let results = null;

  function handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();
    router.push({
      pathname: `/search-result`,
      query: { ids: results.map((el) => el.objectID), query: queryString }
    });
  }
  const algoliaClient = algoliasearch(algolia.appId, algolia.key);

  // Proxy permettant de limiter le nombre de requetes envoyées à algolia
  const searchClient = {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 1,
            nbPages: 0,
            page: 0,
            processingTimeMS: 0,
            query: ''
          }))
        });
      }
      return algoliaClient.search(requests);
    }
  };

  function handleClick(event, resource) {
    event.stopPropagation();
    if (resource.resource_type === 'CHANNEL') handleSubscription(resource);
    else if (resource.payment_link !== null) handlePayment(resource);
    else if (resource.resource_type === 'OAD') {
      router.push({
        pathname: `/oad/${getResourceSlug({
          resourceType: resource.resource_type,
          title: resource.title,
          idResource: resource.objectID,
          code: resource.code
        })}`
      });
    } else if (!resource.resource_type) {
      router.push({
        pathname: '/expert/[id]',
        query: { id: resource.objectID }
      });
    } else {
      router.push({
        pathname: `/${resource.resource_type.toLowerCase()}/[slug]`,
        query: {
          slug: getResourceSlug({
            resourceType: resource.resource_type,
            title: resource.title,
            idResource: resource.objectID,
            slug: slugifyCustom(resource.title)
          })
        }
      });
    }
  }

  function handlePayment(resource) {
    if (!user) {
      setModalShow(true);
      return;
    }
    if (user.resourceLicences?.filter((el) => el.code === resource.code).length > 0) {
      router.push({
        pathname: `/${resource.resource_type.toLowerCase()}/${getResourceSlug({
          resourceType: resource.resource_type,
          title: resource.title,
          idResource: resource.objectID,
          code: resource.code
        })}`
      });
    } else {
      setPaymentLink(resource.payment_link);
      setShowPaymentModal(true);
    }
  }

  function handleSubscription(resource) {
    if (!loading) {
      if (!user) {
        setModalShow(true);
        return;
      }
      if (user.userStatus === 'CREATED') {
        setIsConnected(true);
        setModalShow(true);
        return;
      }
      resource.idResource = resource.objectID;
      if (!isSubscribed(user, resource) && user.idSso) {
        setClicked(resource.objectID);
        setLoading(true);
        getResource(resource.objectID)
          .then((result) => {
            const channel = result.data[0];
            subscribe(user.idSso, channel.idChannelPartner)
              .then(() => {
                setLoading(false);
                updateUser();
                router.push({
                  pathname: '/channel/[slug]',
                  query: { slug: getResourceSlug(channel) }
                });
              })
              .catch((err) => {
                console.log(err);
                setLoading(false);
              });
          })
          .catch((err) => {
            console.log(err);
            setLoading(false);
          });
      } else {
        router.push({
          pathname: '/channel/[slug]',
          query: {
            slug: getResourceSlug({
              resourceType: resource.resource_type,
              title: resource.title,
              idResource: resource.objectID
            })
          }
        });
      }
    }
  }

  function handleClose(event) {
    if (event) event.stopPropagation();
    setModalShow(false);
  }

  function handleShowResult() {
    setShowResult(true);
  }

  function handleHideResult() {
    // Ajout d'un timeout car si les propositions se ferment immédiatement, la redirection n'est pas faite
    setTimeout(() => {
      if (!loading) setShowResult(false);
    }, 500);
  }

  function StateResults({ searchResults }) {
    const hasResults = searchResults && searchResults.nbHits !== 0;
    if (searchResults) {
      results = searchResults.hits.filter((result) => {
        return !(
          result.resource_type === 'CHANNEL' &&
          result.channel_status === 'HIDDEN' &&
          !isSubscribed(user, { idResource: result.objectID })
        );
      });
    }

    useEffect(() => {
      setShow(searchResults !== null && searchResults.query !== '');
      setQueryString(searchResults?.query);
    }, [searchResults]);

    return (
      <div className="search-proposals" hidden={!showResult || searchResults === null || searchResults.query === ''}>
        <div className="results" hidden={hasResults}>
          Pas de résultats
        </div>
        {searchResults &&
          searchResults.hits &&
          showResult &&
          results &&
          results.slice(0, 5).map((hit) => (
            <div className="results" key={hit.objectID} onClick={(e) => handleClick(e, hit)}>
              {hit.title || hit.first_name + ' ' + hit.last_name}
              {loading && clicked === hit.objectID ? (
                <Spinner size="sm" as="span" animation="border" role="status" aria-hidden="true" variant="warning" />
              ) : (
                ''
              )}
            </div>
          ))}
      </div>
    );
  }

  StateResults.propTypes = {
    searchResults: PropTypes.object
  };

  const CustomStateResults = connectStateResults(StateResults);

  // Attention au rafraichissement du composant, et notamment aux potentielles boucles infinies. A chaque rafraichissement une requete est envoyée à algolia

  return (
    <SearchInputContainer>
      <InstantSearch indexName={algolia.name} searchClient={searchClient}>
        <SearchBox
          className={showResult && show ? 'hasResults' : ''}
          translations={{
            placeholder: "Votre recherche c'est ici !"
          }}
          onFocus={handleShowResult}
          onBlur={handleHideResult}
          onSubmit={handleSubmit}
          submit={
            <>
              {'Rechercher'}
              <SearchIcon className="ais-SearchBox-submitIcon" />
            </>
          }
        />
        <CustomStateResults />
      </InstantSearch>
      <ImpossibleAction show={modalShow} handleClose={handleClose} isConnected={isConnected} />
      <PaymentModal show={showPaymentModal} handleClose={() => setShowPaymentModal(false)} link={paymentLink} />
    </SearchInputContainer>
  );
};

export default SearchInput;
